import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiProvider } from './ApiProvider';
import { MapProvider } from './MapProvider';

@Injectable({
  providedIn: 'root'
})

export class UserProvider {

  private path = 'user';
  private paths = 'users';

  constructor(
    private ApiProvider: ApiProvider,
    private MapProvider: MapProvider
  ) { }

  authenticate({ username, password }) {
    return this.ApiProvider.post(`${this.path}/authenticate`, {
      username,
      password
    }).pipe(map((res: any) => {

      if (res.result) {
        this.MapProvider.set(this.MapProvider.USER, {
          username,
          password
        });
      }

      return res;

    }));
  }

  post(username, password, domain) {

    const user = this.MapProvider.get(this.MapProvider.USER);

    return this.ApiProvider.post(this.path, {
      username,
      password,
      domain,
      admin: user
    });
  }

  putPassword(password, id) {

    const user = this.MapProvider.get(this.MapProvider.USER);

    return this.ApiProvider.put(`${this.path}/password`, {
      id,
      password,
      admin: user
    });
  }

  putQuota(quota, id) {

    const user = this.MapProvider.get(this.MapProvider.USER);

    return this.ApiProvider.put(`${this.path}/quota`, {
      id,
      quota,
      admin: user
    });
    
  }

  get(skip, limit, domain) {
    const user = this.MapProvider.get(this.MapProvider.USER);
    return this.ApiProvider.get(`${this.paths}/${skip}/${limit}/${domain}/${user.username}/${user.password}`);
  }

}
