import { Route } from '@angular/router';
import { PageNotFoundComponent } from './404';
import { AuthGuardProvider } from './providers';

export const MODULE_ROUTES: Route[] = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuardProvider]
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

export const MODULE_COMPONENTS = [
  PageNotFoundComponent
];
