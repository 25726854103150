import { Component } from '@angular/core';

@Component({
  selector: 'page-not-found',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})

export class PageNotFoundComponent {
  constructor() { }
}
