export const Environment = {
  api_version: 1,
  api_url: 'https://api-mails.primecode.company'
};

export const Domains = [
  'primecode.company',
  'prime-code.com',
  'prime-code.io',
  'mydnamatch.eu',
  'altenrg.com',
  'shik.bg',
  'aentre.ru',
  'aentre.com',
  'credify.finance',
  'credefi.finance',
  'savethebite.com',
  'ibalr.com',
  'banskoresidence.bg',
  'mymailbg.com'
]
