import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './app.component.scss'
  ],
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent {

  constructor() { }

}
