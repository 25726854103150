import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MapProvider } from './MapProvider';
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class AuthGuardProvider implements CanActivate {

  constructor(
    private MapProvider: MapProvider,
    private Router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    const user = this.MapProvider.get(this.MapProvider.USER);

    if (user == null) {
      this.Router.navigate(['/account', 'login']);
      return false;
    }

    return true;

  }

}
